import cache from "memory-cache";
import { useEffect, useState } from "react";
import router from "next/router";
import Loading from "components/Loading";
import DefaultErrorPage from "next/error";

export default function Home() {
    const [uniqueLinkDomain, setUniqueLinkDomain] = useState("");
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        if (window?.location?.hostname) {
            const cachedFetch = async (url: string, minutes: number) => {
                const cached = cache.get(url);
                if (cached) {
                    return cached;
                }
                const res = await fetch(url);
                const data = await res.json();
                cache.put(url, data, 1000 * 60 * minutes);
                return data;
            };

            const endpoint = `${process.env.NEXT_PUBLIC_REACT_APP_API_ADDRESS}/careerssettings/uniquelinkbydomain/${window.location.hostname}`;

            cachedFetch(endpoint, 5).then((data) => {
                if (data?.uniqueLink) {
                    setUniqueLinkDomain(data.uniqueLink);
                }
                setFinished(true);
            });
        }
    }, []);

    if (!finished) {
        return <Loading />;
    }

    if (uniqueLinkDomain) {
        router.push(`${uniqueLinkDomain}/home`);
    }

    return <DefaultErrorPage statusCode={404} />;
}

export async function getStaticProps(props: any) {
    console.log("getStatisProps home index props", props);

    return {
        props: {},
    };

    // return {
    //     // returns the default 404 page with a status code of 404
    //     notFound: true,
    // };
}
