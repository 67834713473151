import React from "react";

/**
 * tempsession: Value that only is persisted temporarily (not in db) |
 * userdb: User session (persisted in database) |
 * userdb: Company session (persisted in database)
 */
export type GlobalValuesType = "tempsession" | "userdb" | "companydb";

interface IGlobalValuesState {
    name: string;
    value: Compleo.IObject;
    type: GlobalValuesType;
}

export type IGlobalValues = {
    [K in GlobalValuesType]: Compleo.IObject;
};

export interface IGlobalValuesDispatch {
    addOrEditGlobalValue: (params: IGlobalValuesState) => void;
}

const GlobalValuesContext = React.createContext<IGlobalValues | undefined>(
    undefined
);
const GlobalValuesContextDispatch = React.createContext<
    IGlobalValuesDispatch | undefined
>(undefined);

interface IProps {
    children: any;
}

export const GlobalValuesProvider = (props: any) => {
    const { children } = props;
    const [globalValues, setGlobalValues] = React.useState<
        IGlobalValuesState[]
    >([]);

    const userDbData: IGlobalValuesState[] = [];

    const addOrEditGlobalValue = async (params: IGlobalValuesState) => {
        if (params.type === "tempsession") {
            const newGlobalValuesType = [...globalValues].filter(
                (item) => item.name !== params.name && item.type === params.type
            );
            const globalValuesFiltered = [...globalValues].filter(
                (item) => item.type !== params.type
            );
            setGlobalValues([
                ...newGlobalValuesType,
                ...globalValuesFiltered,
                params,
            ]);
        }
    };

    const returnDispatch: IGlobalValuesDispatch = {
        addOrEditGlobalValue: addOrEditGlobalValue,
    };

    const returnObj: IGlobalValues = {
        companydb: {},
        tempsession: {},
        userdb: {},
    };
    globalValues.map((item) => {
        returnObj[item.type][item.name] = item.value;
    });
    userDbData.map((item) => {
        returnObj[item.type][item.name] = item.value;
    });

    return (
        <GlobalValuesContext.Provider value={returnObj}>
            <GlobalValuesContextDispatch.Provider value={returnDispatch}>
                {children}
            </GlobalValuesContextDispatch.Provider>
        </GlobalValuesContext.Provider>
    );
};

const useGlobalValuesState = () => {
    const context = React.useContext(GlobalValuesContext);
    if (!context) {
        throw new Error(
            `useGlobalValues must be used within a GlobalValuesProvider`
        );
    }
    const returnData = context;
    return returnData;
};

const useGlobalValuesDispatch = () => {
    const context = React.useContext(GlobalValuesContextDispatch);
    if (!context) {
        throw new Error(
            `useGlobalValues must be used within a GlobalValuesDispatchProvider`
        );
    }
    const returnData = context;
    return returnData;
};

const useGlobalValues: () => [IGlobalValues, IGlobalValuesDispatch] = () => {
    return [useGlobalValuesState(), useGlobalValuesDispatch()];
};

export default useGlobalValues;
