module.exports = {
    locales: ["pt-BR", "en-US", "en-UK"],
    defaultLocale: "pt-BR",
    loader: false,
    pages: {
        "/": ["HOME", "ERRORPAGE"],
        "/joblist": ["JOBLIST", "ERRORPAGE"],
        "/jobdetail": ["JOBDETAIL", "ERRORPAGE"],
        "/jobapplication": ["JOBAPPLICATION", "ADDRESSFIELD", "ERRORPAGE"],
        "/login": ["LOGIN", "ERRORPAGE"],
        "/signup": ["SIGNUP", "ERRORPAGE"],
        "/confirmsignup": ["CONFIRMSIGNUP", "ERRORPAGE"],
        "/forgotpassword": ["FORGOTPASSWORD", "ERRORPAGE"],
        "/aboutus": ["ABOUTUS", "ERRORPAGE"],
    },
};
