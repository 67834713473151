import { createTheme } from "@material-ui/core/styles";
import { ThemeOptions, Theme } from "@material-ui/core/styles";
import themeObj from "./theme.json";

export const defaultThemeOptions: ThemeOptions = JSON.parse(
    JSON.stringify(themeObj)
);

const themeOptions: ThemeOptions = JSON.parse(JSON.stringify(themeObj));

const theme: Theme = createTheme(themeOptions);

export default theme;
