import React from "react";
import {
    CircularProgress,
    Grid,
    makeStyles,
    Theme,
    Dialog,
} from "@material-ui/core";

interface ILoading {
    size?: number;
}

const useStyles = makeStyles((theme) => ({
    loader: {
        position: "relative",
        zIndex: 999,
        "&::before": {
            position: "absolute",
            content: "",
            background: "#fff",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "80%",
            borderRadius: "10rem",
            border: "3px solid white",
            boxShadow: "inset 0px 0px 100px -70px #111",
        },
    },
}));

const LoadingRouter = (props: ILoading) => {
    const { size = 100 } = props;
    const classes = useStyles();

    return (
        <Dialog
            open={true}
            fullScreen
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                },
            }}
        >
            <Grid
                container
                alignContent="center"
                alignItems="center"
                justify="center"
                style={{
                    position: "absolute",
                    top: "50%",
                    zIndex: 9999,
                }}
            >
                <Grid item xs={1}>
                    <CircularProgress size={size} aria-label="progress" />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default LoadingRouter;
