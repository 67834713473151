import React from "react";

export interface ISessionValuesState {
    [K: string]: any;
}

export interface IAction {
    [K: string]: any;
}

const SessionValuesContext = React.createContext<
    ISessionValuesState | undefined
>(undefined);

const SessionValuesContextDispatch = React.createContext<
    React.Dispatch<IAction> | undefined
>(undefined);

const reducer = (
    state: ISessionValuesState,
    action: IAction
): ISessionValuesState => {
    return { ...state, ...action };
};

const defaultValues: ISessionValuesState = {
    menuWidth: 90,
    fullMenu: false,
};

export const SessionValuesProvider = (props: any) => {
    const { children } = props;

    const [sessionValues, dispatchSessionValues] = React.useReducer(
        reducer,
        defaultValues
    );

    return (
        <SessionValuesContext.Provider value={sessionValues}>
            <SessionValuesContextDispatch.Provider
                value={dispatchSessionValues}
            >
                {children}
            </SessionValuesContextDispatch.Provider>
        </SessionValuesContext.Provider>
    );
};

const useSessionValuesState = () => {
    const context = React.useContext(SessionValuesContext);
    if (!context) {
        throw new Error(
            `useSessionValues must be used within a SessionValuesProvider`
        );
    }
    const returnData = context;
    return returnData;
};

const useSessionValuesDispatch = () => {
    const context = React.useContext(SessionValuesContextDispatch);
    if (!context) {
        throw new Error(
            `useSessionValues must be used within a SessionValuesDispatchProvider`
        );
    }
    const returnData = context;
    return returnData;
};

const useSessionValues: () => [
    ISessionValuesState,
    React.Dispatch<IAction>
] = () => {
    return [useSessionValuesState(), useSessionValuesDispatch()];
};

export default useSessionValues;
