import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import App, { AppProps, NextWebVitalsMetric } from "next/app";
import type { Session } from "next-auth";
import Head from "next/head";
import { GlobalValuesProvider } from "../_ReactContext/GlobalValues";
import { SessionValuesProvider } from "../_ReactContext/SessionValues";

import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { QueryClient, QueryClientProvider } from "react-query";
import appWithI18n from "next-translate/appWithI18n";
import i18nConfig from "../../i18n";
import { NotificationProvider } from "_ReactContext/NotificationContext";
import LoadingRouter from "components/Loading/LoadingRouter";
import Router from "next/router";
import { SessionProvider } from "next-auth/react";
import { defaultThemeOptions } from "styles/siteTheme";
import "styles/globalStyles.css";
import * as gtag from "lib/gtag";
import GoogleAnalytics from "components/Analytics/GA";
import "intl-pluralrules";
const queryClient = new QueryClient();
const isProduction = process.env.NODE_ENV === "production";
const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

export function reportWebVitals(metric: NextWebVitalsMetric) {
    console.log(`reportWebVitals: ${JSON.stringify(metric)}`);
}

function MyApp(props: AppProps<{ session: Session }>) {
    const { Component, pageProps } = props;
    const [loading, setLoading] = useState(false);

    Router.events.on("routeChangeStart", (evts: any[]) => {
        setLoading(true);
    });

    Router.events.on("routeChangeComplete", (url: any) => {
        setLoading(false);

        if (isProduction && GA_TRACKING_ID) {
            gtag.pageview(url, GA_TRACKING_ID);
        }
    });

    const defaultTheme = createTheme(defaultThemeOptions);

    useEffect(() => {
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles && jssStyles.parentElement) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    return (
        <>
            <Head>
                <title>Trabalhe Conosco</title>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
            </Head>
            <QueryClientProvider client={queryClient}>
                <SessionValuesProvider>
                    <NotificationProvider>
                        <SessionProvider
                            session={pageProps.session}
                            refetchInterval={59 * 60}
                        >
                            <GlobalValuesProvider>
                                <ThemeProvider theme={defaultTheme}>
                                    <CssBaseline />
                                    {loading && <LoadingRouter />}
                                    <Component {...pageProps} />
                                </ThemeProvider>
                            </GlobalValuesProvider>
                        </SessionProvider>
                    </NotificationProvider>
                </SessionValuesProvider>
            </QueryClientProvider>
            {isProduction && GA_TRACKING_ID && (
                <GoogleAnalytics gaTrackingId={GA_TRACKING_ID} />
            )}
        </>
    );
}

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
};

export default appWithI18n(MyApp as any, {
    ...i18nConfig,
    skipInitialProps: true,
});
